import React, { useState } from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { Modal } from "antd"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { checkShowForm, getYouTubeEmbedUrl, initializeHubspotForm, isYoutubeUrl } from "../utils/gatsby-helpers"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"

import NewLayout from "../components/new_layout"
import Opinions from "../components/v2023/Opinions/opinions"
import YoutubeVideo from "../components/v2024/video/youtube-video"
import { redirectToThankYou } from "../utils/thank-you-access"


const LandingGenericPage = ({ data, pageContext, location }) => {
    const lang = pageContext.langKey
    const generic = data.generic
    const form = generic.hubspot_form

    const [indexSelected, setIndexSelected] = useState(0)
    const [isModalVisible, setIsModalVisible] = useState(checkShowForm(location))
    const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)
    const [isExtraVideoModalVisible, setIsExtraVideoModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const toggleExtraVideoModal = () => {
        setIsExtraVideoModalVisible(!isExtraVideoModalVisible)
    }

    const scrollAndShowModal = () => {
        scrollTo("#generic-landing-header")
        showModal()
    }

    if (form) {
        let onSubmit = () => {}
        if (generic.thank_you_tofu_mofu) {
            onSubmit = () => redirectToThankYou(lang, generic.thank_you_tofu_mofu.url)
        } else if (generic.thank_you_bofu) {
            onSubmit = () => redirectToThankYou(lang, generic.thank_you_bofu.url)
        }
        initializeHubspotForm(form.portal_id, form.form_id, "#form-generic-landing", form.region, { promo_name: generic.hubspot_promo_name }, onSubmit)
    }


    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={generic.seo.title}
                description={generic.seo.meta_description}
                translates={pageContext.translates}
                image={generic.seo.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--50 white-section green-sm" id="generic-landing-header">
                    <div className="container">
                        <div className="container__50">
                            {generic.tag &&
                                <div className="tag tag--highlight">{generic.tag}</div>
                            }
                            <h1 className="merriweather">{generic.title}</h1>
                            <div className="container__text">
                                <ReactMarkdown children={generic.description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            {form && <button className="button button--xl" onClick={showModal}>{generic.button}</button>}
                            {generic.button_url && !isYoutubeUrl(generic.button_url) &&
                                <a href={generic.button_url} className="button button--xl" target="_blank" rel="noreferrer">{generic.button}</a>}
                            {generic.button_url && isYoutubeUrl(generic.button_url) &&
                                <button className="button button--xl" onClick={() => setIsVideoModalVisible(true)}>{generic.button}</button>}
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            {generic.hero_image &&
                                <GatsbyImage
                                    alt={generic.hero_image.alternativeText}
                                    image={getImage(generic.hero_image.localFile)}
                                />
                            }
                        </div>
                    </div>
                </div>

                {generic.extra_video &&
                    <div className="main__section main__section--100">
                        <div className="container">
                            <YoutubeVideo preview={{localFile: generic.extra_video.video_preview.localFile, alternativeText: generic.extra_video.video_preview.alternativeText}} video={generic.extra_video.video_url}/>
                        </div>
                    </div>
                }

                <div className="main__section main__section--60-40">
                    <div className="container">
                        <div className="container__60">
                            {generic.vertical_tab.items[indexSelected].icon &&
                                <GatsbyImage
                                    alt={generic.vertical_tab.items[indexSelected].icon.alternativeText}
                                    image={getImage(generic.vertical_tab.items[indexSelected].icon.localFile)}
                                />
                            }
                            <ReactMarkdown
                                children={generic.vertical_tab.items[indexSelected].description}
                                rehypePlugins={[rehypeRaw]}
                            />
                        </div>
                        <div className="container__40">
                            <h2>{generic.vertical_tab.title}</h2>
                            <div className="list">
                                {generic.vertical_tab.items.map((item, index) => (
                                    <div
                                        className={`list__item ${indexSelected === index ? "list__item--active" : ""}`}
                                        key={index}
                                        onClick={() => setIndexSelected(index)}>
                                        {item.title}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        {generic.extra_info &&
                            <div className="container__text">
                                <h2>{generic.extra_info.title}</h2>
                                <ReactMarkdown
                                    children={generic.extra_info.description}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className="main__section">
                    <div className="container">
                        <div className="grid-lg-3-h">
                            {generic.characteristics.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <div className="image-group" key={index} {...animation}>
                                    {item.icon &&
                                        <div>
                                            <GatsbyImage
                                                alt={item.icon.alternativeText}
                                                image={getImage(item.icon.localFile)}
                                            />
                                        </div>
                                    }
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <Opinions lang={lang} />

                <div className="main__section main__section--100 ">
                    <div className="container">
                        <div className="tile">
                            {generic.cta?.name && <h3>{generic.cta.name}</h3>}
                            <div className="tile__body">
                                <ReactMarkdown children={generic.cta.description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <button className="button" onClick={scrollAndShowModal}>
                                {generic.cta.button}
                                <i className="icon-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </main>

            {form &&
                <Modal width={"100%"} visible={isModalVisible} onCancel={handleCancel} footer={null}>
                    <div id="form-generic-landing" className="form-hb" />
                </Modal>
            }

            {isVideoModalVisible &&
                <div className="tc-modal">
                    <div className="tc-modal__content">
                        <div className="tc-modal__content__header">
                            <button className="button-icon button-icon--sm" onClick={() => setIsVideoModalVisible(false)}>
                                <i className="icon-close"></i>
                            </button>
                        </div>
                        <div className="tc-modal__content__body">
                            <iframe
                                loading="lazy"
                                src={getYouTubeEmbedUrl(generic.button_url)}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                            />
                        </div>
                    </div>
                </div>
            }

            {isExtraVideoModalVisible &&
                <div className="tc-modal">
                    <div className="tc-modal__content">
                        <div className="tc-modal__content__header">
                            <button className="button-icon button-icon--sm" onClick={toggleExtraVideoModal}>
                                <i className="icon-close"></i>
                            </button>
                        </div>
                        <div className="tc-modal__content__body">
                            <iframe
                                loading="lazy"
                                src={getYouTubeEmbedUrl(generic.extra_video.video_url)}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                            />
                        </div>
                    </div>
                </div>
            }
        </NewLayout>
    )
}

export default LandingGenericPage

export const landingGenericQuery = graphql`
    query ($id: String!, $langKey: String!) {
        generic: strapiGenericLandingPage (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            id
            tag
            title
            description
            button
            button_url
            hero_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 608
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            vertical_tab {
                title
                items {
                    order
                    title
                    description
                    icon {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 900
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
            characteristics {
                order
                title
                description
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 80
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            extra_info {
                title
                description
            }
            extra_video {
                video_url
                video_preview {
                    name
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1024
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            cta {
                name
                description
                button
            }
            hubspot_promo_name
            hubspot_form {
                id
                region
                portal_id
                form_id
            }
            thank_you_tofu_mofu {
                url
            }
            thank_you_bofu {
                url
            }
        }
    }
`

// button_url
// extra_info {
//     title
//     description
// }
// extra_video {
//     video_url
//     video_preview {
//         name
//         localFile {
//             childImageSharp {
//                 gatsbyImageData(
//                     width: 992
//                     placeholder: BLURRED
//                     formats: [WEBP]
//                 )
//             }
//         }
//     }
// }
